.loader-background {
  display: flex;
  background: rgba(255, 255, 255, 0.7);
  position: absolute;
  top: 0;
  height: 100vh;
  width: 100vw;
  align-items: center;
  justify-content: center;
  z-index: 9000;
}
.first-circle {
  position: absolute;
  animation-name: spin-right;
  animation-duration: 2500ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
.second-circle {
  position: absolute;
  animation-name: spin-left;
  animation-duration: 2500ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.third-circle {
  position: absolute;
  animation-name: spin-right;
  animation-duration: 2500ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes spin-right {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes spin-left {
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(0deg);
  }
}
